import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ScrollToTop from '../components/ScrollToTop';

import p1 from '../assets/images/joanna-kosinska-1_CMoFsPfso-unsplash 2.png';
import p2 from '../assets/images/joanna-kosinska-7ACuHoezUYk-unsplash 2.png';
import p3 from '../assets/images/joanna-kosinska-szFUQoyvrxM-unsplash 2.png';
import p4 from '../assets/images/joanna-kosinska-LAaSoL0LrYs-unsplash 2.png';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead d-flex">
      <div className="container text-center my-auto">
        <h1 className="mb-1">{config.heading}</h1>
        <h3 className="mb-5">
          <em>{config.subHeading}</em>
        </h3>
        <Scroll type="id" element="about" offset={100}>
          <a className="btn btn-primary btn-xl" href="#about">
            About
          </a>
        </Scroll>
      </div>
      <div className="overlay"></div>
    </header>
    <section className="content-section bg-light" id="about">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h2>About Me</h2>
            <p className="lead mb-5">
              I'm a New Orleans-based web developer. Currently working as a
              Software Engineer at Deloitte. I began coding early 2021 when I
              enrolled into BrainStation's Web Development program where I
              gained an immense amount of knowledge and abilities. One of the
              things that gets me extremely excited is when there's a problem to
              solve, the tougher it is the better. Whether it be a puzzle or a
              bug, I enjoy dissecting the problem and breaking it down into
              smaller bits to see what could be done differently and more
              efficiently. <br></br>
              <br></br>I believe that technology allows me to sharpen my mind
              every day with brand-new challenges in a fast pace ever-growing
              field. In my work experience, I have been able to hone my skills
              as a team player as well as being a leader when challenges have
              arised for my colleagues. My recent experiences in tech have
              allowed me to see firsthand the impact that this industry can have
              on our future and nothing excites me more than the opportunity to
              help mold the future by solving one problem at a time. Prior to
              coding I worked in many different fields I've worked as an
              archaeologist, a professional musician in New Orleans, and even
              worked as an Autonomous Vehicle Systems Test Specialist at Argo
              AI.
            </p>
            <Scroll type="id" element="Skills" offset={100}>
              <a className="btn btn-dark btn-xl" href="#Skills">
                What I Offer
              </a>
            </Scroll>
          </div>
        </div>
      </div>
    </section>
    <section
      className="content-section bg-primary text-white text-center"
      id="Skills"
    >
      <div className="container">
        <div className="content-section-heading">
          <h3 className="text-secondary mb-0">Prevalent Skills</h3>
          <h2 className="mb-5">What I Offer</h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
            <span className="service-icon rounded-circle mx-auto mb-3">
              <i className="icon-screen-smartphone"></i>
            </span>
            <h4>
              <strong>Languages</strong>
            </h4>
            <p className="text-faded mb-0">Python and Javascript</p>
          </div>
          <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
            <span className="service-icon rounded-circle mx-auto mb-3">
              <i className="icon-pencil"></i>
            </span>
            <h4>
              <strong>Front-End & Styling</strong>
            </h4>
            <p className="text-faded mb-0">
              HTML, CSS, SASS, React, Bootstrap, Gatsby
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
            <span className="service-icon rounded-circle mx-auto mb-3">
              <i className="icon-screen-desktop"></i>
            </span>
            <h4>
              <strong>Back-end & Databases</strong>
            </h4>
            <p className="text-faded mb-0">
              Node.js, Express, Firebase, MySQL, REST APIs, Nginx
            </p>
          </div>
          <div className="col-lg-3 col-md-6">
            <span className="service-icon rounded-circle mx-auto mb-3">
              <i className="icon-wrench"></i>
            </span>
            <h4>
              <strong>Tools</strong>
            </h4>
            <p className="text-faded mb-0">
              Docker, Kubernetes, Concourse CI, Travis CI, Figma, Git, Heroku,
              Linux, Postman, Netlify
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="callout">
      <div className="container text-center">
        <h2 className="mx-auto mb-5">
          Check Out My Github to see more projects!
        </h2>
        <a
          className="btn btn-primary btn-xl"
          href="https://github.com/Miguel787"
        >
          Check it out!
        </a>
      </div>
    </section>
    <section className="content-section" id="portfolio">
      <div className="container">
        <div className="content-section-heading text-center">
          <h3 className="text-secondary mb-0">Portfolio</h3>
          <h2 className="mb-5">Recent Projects</h2>
        </div>
        <div className="row no-gutters">
          <div className="col-lg-6">
            <a className="portfolio-item" href="/greenroom">
              <span className="caption">
                <span className="caption-content">
                  <h2>GreenRoom</h2>
                  <p className="mb-0">
                    A social media web app for musicians to break into their
                    local scene and connect with other musicians.
                  </p>
                </span>
              </span>
              <img className="img-fluid" src={p1} alt="" />
            </a>
          </div>
          <div className="col-lg-6">
            <a className="portfolio-item" href="/nbc">
              <span className="caption">
                <span className="caption-content">
                  <h2>Internal data app</h2>
                  <p className="mb-0">
                    A data visualization app made for a 24 hour hackathon group
                    project with UX designer students and other web dev students
                    for NBC Universal Telemundo
                  </p>
                </span>
              </span>
              <img className="img-fluid" src={p2} alt="" />
            </a>
          </div>
          <div className="col-lg-6">
            <a className="portfolio-item" href="/memepto">
              <span className="caption">
                <span className="caption-content">
                  <h2>Memepto</h2>
                  <p className="mb-0">
                    24 hour hackathon to make a Meme & crypto currency page with
                    one partner
                  </p>
                </span>
              </span>
              <img className="img-fluid" src={p3} alt="" />
            </a>
          </div>
          <div className="col-lg-6">
            <a className="portfolio-item" href="/bootcamp">
              <span className="caption">
                <span className="caption-content">
                  <h2>KGDesigns </h2>
                  <p className="mb-0">
                    Portfolio site for UX designer Karina Gutierrez
                  </p>
                </span>
              </span>
              <img className="img-fluid" src={p4} alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
    {/* <section className="content-section bg-primary text-white">
      <div className="container text-center">
        <h2 className="mb-4">The buttons below are impossible to resist...</h2>
        <a href="/#" className="btn btn-xl btn-light mr-4">
          Click Me!
        </a>
        <a href="/#" className="btn btn-xl btn-dark">
          Look at Me!
        </a>
      </div>
    </section> */}
    <section id="contact" className="map">
      <ScrollToTop />
      <Footer />
    </section>
  </Layout>
);

export default IndexPage;
